<template>
  <div
    class="h-screen flex w-full items-center justify-center"
    style="background-color: white"
  >
    <div
      :class="getDentalVersionActive ? 'bg-image-dental' : 'bg-image-kuyum'"
      class="bg-image vx-col hidden md:block lg:w-8/12 md:w-6/12 p-8 h-full"
    >
      <div class="w-full h-full flex items-center justify-center">
        <div class="w-full text-center">
          <p style="font-size: 22px" class="w-full text-white mb-4">
            {{ $t("Forgot_Pass_Message_1") }}
          </p>
          <p
            style="font-size: 36px; font-weight: 600"
            class="w-full text-white"
          >
            {{ $t("Forgot_Pass_Message_2") }}
          </p>
        </div>
      </div>
    </div>
    <div class="vx-col sm:w-full lg:w-4/12 md:w-6/12">
      <div class="flex justify-center">
        <div class="p-12">
          <div class="mb-4">
            <img
              v-if="!getDentalVersionActive"
              src="@/assets/images/pages/novafab-logo.png"
              alt=""
            />
            <img v-else src="@/assets/images/pages/dentafab-logo.png" alt="" />
          </div>

          <div>
            <vs-input
              color="dark"
              @click="showErrorMessageBox = false"
              type="email"
              :label-placeholder="$t('Email')"
              v-model="email"
              class="w-full mb-8"
              :danger="showErrorMessageBox"
              :danger-text="errorMessage"
            />

            <vs-button
              color="dark"
              class="w-full px-4 w-full mt-3 mb-8"
              @click="sendEmail"
            >
              {{ $t("Forgot_Pass_Message_3") }}
            </vs-button>

            <vs-button
              color="dark"
              type="border"
              to="/login"
              class="px-4 w-full"
            >
              {{ $t("Register_Back_To_Login") }}
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

export default {
  data() {
    return {
      email: "",
      showErrorMessageBox: false,
      errorMessage: "",
    };
  },
  computed: {
    getDentalVersionActive() {
      return this.$store.getters.getDentalVersionActive;
    },
  },
  methods: {
    sendEmail() {
      Api.post(API.BASEURL + API.FORGOTPASS, this.handleSendEmail, {
        email: this.email,
      });
    },
    handleSendEmail(status, data) {
      if (status == 200) {
        this.$vs.notify({
          title: this.$t("Successful"),
          text: this.$t("Forgot_Pass_Message_4"),
          color: "success",
        });
        this.$router.push("/login").catch((error) => console.error(error));
      } else if (status == 400) {
        this.showErrorMessageBox = true;
        this.errorMessage = data.data.email[0];
      }
    },
  },
  created() {
    if (localStorage.getItem("token") != null) {
      Api.logout().then((response) => {
        if (response.status == 200) {
          this.$store.dispatch("logout");
        }
      });
    }
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 992px) {
  .loginCardInput {
    border-bottom-right-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
  }
}

.loginCard {
  border-bottom-right-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  background-color: black;
}

.loginCardInput {
  border-bottom-right-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.bg-image-dental {
  background-image: url("/img/dental-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
.bg-image-kuyum {
  background-image: url("/img/kuyum-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
</style>
